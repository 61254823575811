




























import { Component, Vue } from 'vue-property-decorator';
import Icon1 from '@/assets/icons/intro/splash_1.svg';
import Icon3 from '@/assets/icons/intro/splash_3.svg';
import {CookieService} from '@/services/data/cookie.service';
import {i18n} from '@/main';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.intro.title'),
      meta: [
        {
          name: 'description',
          content: i18n.tc('meta.intro.description')
        }
      ]
    };
  },
  components: {
    Icon1,
    Icon3,
  }
})
export default class IntroPage extends Vue {
  private currentScreen = 1;
  private itemsList = [
    {
      name: 'splash.item1',
      icon: Icon1
    },
    {
      name: 'splash.item2',
      icon: Icon3
    }
  ];
  private sliderBreakpoints = {
    320: {
      slidesPerView: 1,
      spaceBetween: 25,
      allowTouchMove: false
    }
  };
  private nextScreen() {
    if (this.currentScreen < 2) {
      this.currentScreen += 1;
      this.$emit('nextSlide');
    } else {
      this.completeIntro();
    }
  }
  private completeIntro() {
    CookieService.set('tenantIntroCompleted', true, {path: '/', expires: new Date('Tue, 19 Jan 2038 03:14:07 UTC')});
    this.$router.push({name: 'home'});
  }
}
